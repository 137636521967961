/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-one-expression-per-line */
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import CopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PeopleIcon from '@mui/icons-material/People';
import VideocamIcon from '@mui/icons-material/Videocam';
import { Tooltip } from '@mui/material';
import * as dateFns from 'date-fns';
import ptBR from 'date-fns/locale/pt';
import {
  IconAddProfessional,
  IconDownload,
  IconTranscription,
} from 'presentation/base/icons';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { iRecords, iStore } from 'domain/interfaces/models';
import { History } from 'main/routes';
import { Button } from 'presentation/components/UI/buttons';

import { ListRecordingsConference } from 'domain/usecases/conference/remote';
import { makeRemoteDeleteConference } from 'main/factories/usecases/conference/DeleteConferenceFactory';
import { makeRemoteDownloadConference } from 'main/factories/usecases/conference/DownloadConferenceFactory';
import { makeRemoteListRecordingsConference } from 'main/factories/usecases/conference/ListRecordingsConferenceFactory';
import { makeReduxUpdateControllerConference } from 'main/factories/usecases/conference/UpdateControllerConferenceFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { closeModal } from 'utils/closeModal';

import { ThemeContext } from 'App';
import { GetRegistry } from 'domain/usecases/registry/remote';
import { makeRemoteDownloadConferenceTranscription } from 'main/factories/usecases/conference/DownloadConferenceTranscriptionFactory';
import { makeReduxListFilteredConference } from 'main/factories/usecases/conference/ListFilteredConferenceFactory';
import { makeRemoteDownloadRegistry } from 'main/factories/usecases/registry/DownloadRegistryFactory';
import { makeRemoteGetRegistry } from 'main/factories/usecases/registry/GetRegistryFactory';
import { formatBytes } from 'utils/formatBytes';
import {
  addPadWithZeroes,
  formattedRegistryDate,
  padWithZeroes,
} from 'utils/formattedDate';
import { getRole } from 'utils/getRole';
import { AvatarImage } from '../avatar';
import { translator } from '../i18n';
import Translator from '../i18n/Translator';
import {
  Avatar,
  BlockChainIcon,
  CenteredDivider,
  Container,
  ContentDivider,
  DownloadText,
  HeaderSubtitle,
  HeaderText,
  HeaderTitle,
  Info,
  ListContainer,
  ListItem,
  LoadingCenter,
  RecordingsContainer,
  RecordingsDownloadIcon,
  RecordingsItemContainer,
  RecordingsText,
  Records,
  RegistryItemContainer,
  RegistryText,
  RowContainer,
  SpacedRowContainer,
  Status,
  User,
} from './styles/StyledConferenceDetails';

const ConferenceDetails: React.FC = (): JSX.Element => {
  const url = window.location.pathname;
  const [recordings, setRecordings] = useState<
    ListRecordingsConference.Model['records']
  >([]);
  const [blockChainRecordings, setBlockChainRecordings] = useState<
    GetRegistry.Model['records']
  >([]);
  const [loading, setLoading] = useState<{ [id: string]: boolean }>({});
  const [loadingTranscription, setLoadingTranscription] =
    useState<boolean>(false);
  const [loadingRegistry, setLoadingRegistry] = useState<boolean>(false);

  const { controller, records } = useSelector(
    (store: iStore) => store.conference,
  );

  const user = useSelector((store: iStore) => store.auth.user);

  const { theme } = useContext(ThemeContext);

  const conference: iRecords | undefined = records
    ? records.filter(el => {
        return el.id === controller.selected;
      })[0]
    : undefined;

  const roleRedux = useMemo(
    () =>
      user?.administrator
        ? getRole(user.administrator, user.org.id, user.orgUnit.id)
        : 'STANDARD',
    [user?.administrator, user?.org.id, user?.orgUnit.id],
  );

  const formartDate = (conf: iRecords) => {
    const scheduled = new Date(conf?.scheduled);

    return (
      <>
        {dateFns.format(scheduled, "EEEE, dd 'de' MMMM - ", {
          locale: ptBR,
        })}
        {padWithZeroes(scheduled.getHours(), 2)}:
        {padWithZeroes(scheduled.getMinutes(), 2)}
      </>
    );
  };

  const handleClose = useCallback(() => {
    makeReduxUpdateControllerConference().updateController({
      showDetails: false,
    });
  }, []);

  const handleDownload = useCallback(
    (id: number) => {
      if (conference?.id && id) {
        setLoading({ ...loading, [id]: true });

        makeRemoteDownloadConference()
          .download({
            conferenceId: Number(conference.id),
            recordingId: id,
          })
          .then(res => {
            const scheduledDate = new Date(conference.scheduled);
            const filename = `WiseAgenda_Reuniao_${addPadWithZeroes(
              scheduledDate.getDate(),
            )}-${addPadWithZeroes(
              scheduledDate.getMonth() + 1,
            )}-${scheduledDate.getFullYear()}_${addPadWithZeroes(
              scheduledDate.getHours(),
            )}-${addPadWithZeroes(scheduledDate.getMinutes())}.mp4`;

            const a = document.createElement('a');
            a.href = res.url;
            a.setAttribute('download', filename);
            a.style.display = 'none';
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            toast.success('Gravação baixada com sucesso');
          })
          .catch(() => {
            // toast.error('Não foi possível realizar o download da gravação');
          })
          .finally(() => {
            setLoading({ ...loading, [id]: false });
          });
      }
    },
    [conference?.id, conference?.scheduled, loading],
  );

  const handleDelete = useCallback(() => {
    if (!conference?.id) return;

    makeReduxActiveMessage().active({
      active: 'confirm',
      content: Translator('Deseja realmente excluir a reunião?'),
      title: Translator('Remoção de Reunião'),
      actionOk: () => {
        makeRemoteDeleteConference()
          .delete({
            conferenceId: Number(conference?.id),
          })
          .then(res => {
            makeReduxUpdateControllerConference().updateController({
              showDetails: false,
            });

            makeReduxListFilteredConference().list({
              dataControl: {
                limit: 9999,
              },
            });

            toast.success(Translator('Reunião excluida com sucesso'));
            closeModal();
          })
          .catch(err => {
            makeReduxActiveMessage().active({
              active: 'error',
              title: Translator('Remoção de Reunião'),
              content: Translator('Não foi possível remover a reunião.'),
              actionOk: () => closeModal(),
              actionCancel: () => closeModal(),
            });
          });
      },
      actionCancel: () => closeModal(),
    });
  }, [conference?.id]);

  const handleRemoveRecurrenceConference = useCallback(() => {
    if (!conference?.id) return;

    if (conference?.rrule) {
      makeReduxActiveMessage().active({
        active: 'recurringConferenceModal',
        conferenceId: conference?.id,
        actionCancel: () => closeModal(),
        type: 'DELETE',
      });
    } else {
      handleDelete();
    }
  }, [conference?.id]);

  const handleUpdateRecurrenceConference = useCallback(() => {
    if (!conference?.id) return;

    History.getHistory().push(`/appointment/edit/${conference?.id}`, {
      from: url.replace('/', ''),
    });
  }, [conference?.id]);

  const handleDownloadTranscription = useCallback(() => {
    setLoadingTranscription(true);
    makeRemoteDownloadConferenceTranscription()
      .download({
        conferenceId: Number(conference?.id),
      })
      .then(res => {
        const blob = new Blob([res], { type: 'application/pdf' });
        const urlToPdf = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = urlToPdf;
        a.download = 'transcription.pdf';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        toast.success('Transcrição baixada com sucesso');
      })
      .catch(err => {
        // toast.error('Não foi possível realizar o download da gravação');
      })
      .finally(() => {
        setLoadingTranscription(false);
      });
  }, [conference?.id]);

  const handleDowloadRegistry = useCallback(
    (id: number) => {
      if (conference?.id && id) {
        setLoading({ ...loading, [id]: true });

        makeRemoteDownloadRegistry()
          .download({
            conferenceId: Number(conference.id),
            dltId: id,
          })
          .then(res => {
            if (!res) return;

            const blob = new Blob([res], { type: 'application/pdf' });
            const urlToPdf = window.URL.createObjectURL(blob);

            const date = new Date();

            const timestamp = dateFns.getUnixTime(date);

            const dateTimestamp = new Date(timestamp * 1000);

            const formatedHours = dateFns.format(dateTimestamp, 'dd/MM/yyyy');

            const a = document.createElement('a');
            a.href = urlToPdf;
            a.download = `registry_${formatedHours}.pdf`;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            toast.success('Registro baixado com sucesso!');
          })
          .catch(err => {
            toast.error(err);
          })
          .finally(() => {
            setLoading({ ...loading, [id]: false });
          });
      }
    },
    [conference?.id, loading],
  );

  useEffect(() => {
    if (conference?.id && conference.recorded)
      makeRemoteListRecordingsConference()
        .listRecordings({
          conferenceId: Number(conference?.id),
          query: {
            disablePagination: true,
          },
        })
        .then(res => {
          setRecordings(res.records);
        })
        .catch(err => {
          // toast.error('Não foi possível buscar gravações');
        });
  }, [conference?.id]);

  useEffect(() => {
    if (conference?.id && conference?.allowBlockchain) {
      setLoadingRegistry(true);
      makeRemoteGetRegistry()
        .getRegistry({
          conferenceId: Number(conference?.id),
        })
        .then(res => {
          setBlockChainRecordings(res?.records);
        })
        .catch(() => {
          setLoadingRegistry(false);
        })
        .finally(() => {
          setLoadingRegistry(false);
        });
    }
  }, [conference?.id, conference?.allowBlockchain]);

  useEffect(() => {
    return () => {
      handleClose();
    };
  }, [handleClose]);

  return (
    <Container>
      <CloseIcon
        onClick={handleClose}
        style={{ marginBottom: '8px', cursor: 'pointer' }}
      />
      <Tooltip
        title={conference ? conference?.title : ''}
        placement="top"
        arrow
      >
        <HeaderTitle>{conference?.title}</HeaderTitle>
      </Tooltip>
      <HeaderSubtitle style={{ marginBottom: '16px' }}>
        {conference && formartDate(conference)}
      </HeaderSubtitle>
      <div
        style={{
          overflowY: 'auto',
          maxHeight: '100px',
          textAlign: 'justify',
        }}
      >
        <HeaderText>
          <b>Descrição: </b> {conference?.descr}
        </HeaderText>
      </div>
      <RowContainer style={{ marginTop: '16px' }}>
        <FormatListBulletedIcon />
        <HeaderText bold>{Translator('Ações')}</HeaderText>
        <CenteredDivider />
      </RowContainer>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '22px 0px',
          width: '100%',
          gap: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button
          data-testid="btn-edit"
          size="small"
          icon={EditIcon}
          background="#45B4F2"
          rounded
          permissionsV2={conference?.permission.update}
          onClick={handleUpdateRecurrenceConference}
        >
          {Translator('Editar')}
        </Button>
        <Button
          data-testid="btn-delete"
          size="small"
          icon={DeleteIcon}
          background="#45B4F2"
          rounded
          permissionsV2={conference?.permission.delete}
          onClick={handleRemoveRecurrenceConference}
        >
          {Translator('Remover')}
        </Button>
        <a
          href={
            conference?.type === 'RESTRICT'
              ? `/waitingRoom?t=${conference?.short}`
              : `/preconf?t=${conference?.short}`
          }
          target="_blank"
          rel="noreferrer"
          style={{ color: 'white' }}
        >
          <Button
            data-testid="btn-join-conference"
            size="medium"
            icon={IconAddProfessional}
            background={theme.colors.primary.main}
            rounded
            permissionsV2={conference?.permission.join}
          >
            {Translator('Entrar na reunião')}
          </Button>
        </a>
        <Button
          data-testid="btn-copy-link"
          size="medium"
          icon={CopyIcon}
          background={theme.colors.primary.main}
          onClick={() => {
            const link = `${window.location.origin}/${
              conference?.type === 'RESTRICT' ? 'waitingRoom' : 'preconf'
            }?t=${conference?.short}`;

            navigator.clipboard.writeText(link);
            toast.info('URL copiada para a área de transferência');
          }}
          rounded
          permissionsV2={conference?.permission.join}
        >
          {Translator('Copiar link')}
        </Button>
      </div>
      <RowContainer>
        <ManageAccountsIcon />
        <HeaderText bold>{Translator('Organização')}</HeaderText>
        <CenteredDivider />
      </RowContainer>
      <ContentDivider>
        <HeaderText>
          <b>{Translator('Organizador')}: </b>
          {`${conference?.owner.fullName}`}
        </HeaderText>
        <HeaderText>
          <b>{Translator('Órgão')}: </b>
          {`${conference?.orgUnit.org.shortname.toLocaleUpperCase()}/${conference?.orgUnit.shortname.toLocaleUpperCase()}`}
        </HeaderText>
      </ContentDivider>
      <RowContainer>
        <PeopleIcon />
        <HeaderText bold style={{ width: '40%' }}>
          {`Participantes (${conference?.members.total})`}
        </HeaderText>
        <CenteredDivider />
      </RowContainer>
      <ContentDivider>
        <SpacedRowContainer>
          <HeaderText color="#45F275" bold>
            {`Confirmaram: ${conference?.members.confirmedPresence}`}
          </HeaderText>
          {/* Use redish as color */}
          <HeaderText color="#fb6161" bold>
            {`Declinaram: ${conference?.members.confirmedAbsence}`}
          </HeaderText>
          <HeaderText color="#45B4F2" bold>
            {`Talvez: ${conference?.members.maybePresence}`}
          </HeaderText>
          <HeaderText color="#3f3f3f" bold>
            {`Pendentes: ${conference?.members.pendingConfirmation}`}
          </HeaderText>
        </SpacedRowContainer>
        <SpacedRowContainer style={{ marginTop: '8px' }}>
          <ListContainer>
            {!!conference?.participants.length &&
              conference?.participants.map(value => {
                return (
                  <ListItem>
                    <User>
                      <Avatar>
                        <AvatarImage
                          src={value?.user?.avatar}
                          size="mini"
                          name={
                            value?.fullName ??
                            `${value?.user?.firstName} ${value?.user?.lastName}`
                          }
                        />
                      </Avatar>
                      <Info>
                        <div>
                          {value?.fullName ??
                            `${value?.user?.firstName} ${value?.user?.lastName}`}
                        </div>
                        <div>
                          {value.role === 'MODERATOR'
                            ? 'Moderador'
                            : 'Convidado'}
                        </div>
                      </Info>
                    </User>
                  </ListItem>
                );
              })}
          </ListContainer>
        </SpacedRowContainer>
      </ContentDivider>

      {conference?.allowTranscription && (
        <>
          <Records>
            <IconTranscription />
            <HeaderText bold>Transcrição:</HeaderText>
            <CenteredDivider />
          </Records>
          <ContentDivider>
            <RowContainer style={{ justifyContent: 'space-between' }}>
              <HeaderText bold>Download do PDF</HeaderText>
              {loadingTranscription ? (
                <CircularProgress
                  style={{
                    width: '24px',
                    height: '24px',
                    color: `${theme.colors.primary.main}`,
                  }}
                />
              ) : (
                <IconDownload
                  onClick={handleDownloadTranscription}
                  style={{ cursor: 'pointer' }}
                />
              )}
            </RowContainer>
          </ContentDivider>
        </>
      )}

      <Records>
        <VideocamIcon />
        <HeaderText bold>{Translator('Gravações')}:</HeaderText>
        <Status status={recordings.length ? 'in progress' : 'not recorded'}>
          {recordings.length
            ? `${Translator('Gravado')}`
            : `${Translator('Não gravado')}`}
        </Status>
        <CenteredDivider />
      </Records>
      {conference?.recorded && (
        <ContentDivider>
          <RowContainer>
            {/* <HeaderText bold>Status: </HeaderText> */}
          </RowContainer>
          <RecordingsContainer>
            {recordings.length ? (
              recordings.map(rec => (
                <RecordingsItemContainer>
                  <div
                    style={{ width: '80%', borderBottom: '1px solid #c4c4c4' }}
                  >
                    <RecordingsText>{`Gravação ${rec.id}`}</RecordingsText>
                    <RecordingsText color="#c4c4c4">
                      {`${formatBytes(rec.size)}`}
                    </RecordingsText>
                  </div>
                  {loading[rec.id] ? (
                    <CircularProgress
                      style={{
                        width: '30px',
                        height: '30px',
                        color: `${theme.colors.primary.main}`,
                      }}
                    />
                  ) : (
                    <RecordingsDownloadIcon
                      onClick={() => handleDownload(rec.id)}
                      permissionsV2
                    />
                  )}
                </RecordingsItemContainer>
              ))
            ) : (
              <DownloadText
                lighter
                style={{ textAlign: 'center', marginTop: '16px' }}
              >
                {Translator(
                  'Você poderá fazer o download da gravação assim que disponível.',
                )}
              </DownloadText>
            )}
          </RecordingsContainer>
        </ContentDivider>
      )}
      {conference?.allowBlockchain && (
        <div style={{ marginTop: '8rem' }}>
          <Records>
            <BlockChainIcon />
            <HeaderText bold>{translator('Blockchain')}</HeaderText>
            <CenteredDivider />
          </Records>
          <ContentDivider>
            {loadingRegistry ? (
              <LoadingCenter>
                <CircularProgress
                  style={{
                    width: '30px',
                    height: '30px',
                    color: `${theme.colors.primary.main}`,
                  }}
                />
              </LoadingCenter>
            ) : (
              <>
                {blockChainRecordings?.length ? (
                  blockChainRecordings?.map(item => (
                    <RegistryItemContainer>
                      <div
                        style={{
                          width: '100%',
                        }}
                      >
                        <RegistryText>{`Sessão realizada em ${formattedRegistryDate(
                          item?.session_?.created,
                        )} às ${dateFns.format(
                          new Date(item?.session_?.created),
                          'HH:mm',
                        )}`}</RegistryText>
                      </div>
                      {loading[item?.id] ? (
                        <CircularProgress
                          style={{
                            width: '20px',
                            height: '20px',
                            color: `${theme.colors.primary.main}`,
                          }}
                        />
                      ) : (
                        <RecordingsDownloadIcon
                          onClick={() => handleDowloadRegistry(item?.id)}
                          permissionsV2
                        />
                      )}
                    </RegistryItemContainer>
                  ))
                ) : (
                  <DownloadText
                    lighter
                    style={{ textAlign: 'center', marginTop: '16px' }}
                  >
                    {translator(
                      'Você poderá fazer o download do registro assim que estiver disponível.',
                    )}
                  </DownloadText>
                )}
              </>
            )}
          </ContentDivider>
        </div>
      )}
    </Container>
  );
};

export default ConferenceDetails;
