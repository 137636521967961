/* eslint-disable react/jsx-one-expression-per-line */
import React, {
  FormEvent,
  useEffect,
  useContext,
  useCallback,
  useState,
} from 'react';
import { toast } from 'react-toastify';

import { makeReduxLoginAuth } from 'main/factories/usecases/auth/LoginAuthFactory';
import { makeReduxCloseModal } from 'main/factories/usecases/modal/CloseModalFactory';

import {
  IconCodata,
  IconEmail,
  IconPadlock,
  IconPBMeet,
} from 'presentation/base/icons';

import { Button, Input, Modal } from 'presentation/components/UI';
import { Icon } from 'presentation/components/icon';
import { ChangeRoles } from 'presentation/components/changeRoles';

import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { iStore } from 'domain/interfaces/models';
import { ThemeContext } from 'App';
import Translator from 'presentation/components/i18n/Translator';
import { makeRemoteGetInfoAuth } from 'main/factories/usecases/auth/GetInfoAuthFactory.ts';
import { translator } from 'presentation/components/i18n';

import {
  Body,
  Container,
  Left,
  Right,
  Form,
  Title,
  ButtonsContainer,
  FlexContainer,
  RemindMe,
  Forgotpass,
  FooterLeft,
  FooterRight,
  OrLoginOption,
  WelcomeTo,
} from './styles/styledLogin';

const Login: React.FC = (): JSX.Element => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authURL, setAuthURL] = useState('');

  const { loading } = useSelector((store: iStore) => store.auth);

  const { push } = useHistory();
  const { theme } = useContext(ThemeContext);

  const handleLogin = useCallback(
    // eslint-disable-next-line consistent-return
    (e: FormEvent) => {
      e.preventDefault();

      if (!loading) {
        if (email === '' || password === '') {
          return toast.error('Preencha todos os campos.');
        }

        makeReduxLoginAuth().login({
          body: {
            login: email,
            password,
          },
        });
      }
    },
    [email, loading, password],
  );

  const handleCodataRedirect = useCallback(() => {
    window.location.href = authURL;
  }, [authURL]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.href.replace('#', '?'));
    const code = query.get('code');

    if (code) {
      makeReduxLoginAuth().login({
        body: {
          authMode: 'EXTERNAL',
          identityCode: code,
        },
      });
    }

    makeRemoteGetInfoAuth()
      .get({})
      .then(
        res => {
          if (res.authMode === 'EXTERNAL' && res.authentication.authURL) {
            setAuthURL(res.authentication.authURL);
          }
        },
        err => {
          console.log('err: ', err);
        },
      );
  }, []);

  return (
    <Container banner={theme.images.banner}>
      <Body>
        <Left>
          {theme.images.logo.main && (
            <img src={theme.images.logo.main} alt="Logo" />
          )}
          <Form id="loginAuthentication" onSubmit={handleLogin}>
            <Title>{translator('Fazer Login')}</Title>
            <Input
              data-testid="input-email"
              id="loginInput"
              name="login"
              type="text"
              label={translator('E-mail')}
              icon={IconEmail}
              placeholder={translator('Digite o email cadastrado')}
              value={email}
              onChange={e => setEmail(e.target.value)}
              autoFocus
            />

            <Input
              data-testid="input-password"
              id="loginPassword"
              type="password"
              name="password"
              label={translator('Senha')}
              icon={IconPadlock}
              placeholder={translator('Digite sua senha')}
              value={password}
              onChange={e => setPassword(e.target.value)}
            />

            <FlexContainer>
              <RemindMe>
                <input type="checkbox" />
                <span>{translator('Lembrar-me')}</span>
              </RemindMe>
              <Forgotpass
                data-testid="btn-forgot"
                id="loginForgotPass"
                href="/pwd/forgot"
              >
                {translator('Esqueceu sua senha?')}
              </Forgotpass>
            </FlexContainer>

            <ButtonsContainer>
              <Button
                height="48px"
                data-testid="btn-login"
                id="loginSubmit"
                type="submit"
                disabled={!password || !email || loading}
              >
                {loading
                  ? `${translator('Carregando...')}`
                  : translator('Entrar')}
              </Button>
              {authURL && (
                <>
                  <div className="login-type">
                    <div className="gray-line" />
                    <OrLoginOption>{Translator('ou')}</OrLoginOption>
                    <div className="gray-line" />
                  </div>
                  <Button
                    height="50px"
                    data-testid="btn-codata-login"
                    background="transparent"
                    color="#747474"
                    style={{ border: '1px solid #747474' }}
                    icon={IconCodata}
                    onClick={() => handleCodataRedirect()}
                  >
                    Autenticar com a CODATA
                  </Button>
                </>
              )}
            </ButtonsContainer>
          </Form>
          <FooterLeft>
            <a
              href="https://docs.google.com/document/d/168dn-x43EsQbIUXHC659mf58z697dixTRBuic-DD93E"
              target="_blank"
              rel="noreferrer"
            >
              {translator('Versão')} 2.49.2
            </a>
            <p>
              © {theme.systemName}.
              {translator(' Todos os direitos reservados.')}
            </p>
          </FooterLeft>
        </Left>
        <Right>
          <img
            src={theme.images.favicon}
            alt="Favicon"
            width="120px"
            height="80px"
          />
          <WelcomeTo>
            Seja bem vindo ao
            <h1>{theme.systemName ?? theme.emailParams.systemName}</h1>
          </WelcomeTo>
          <div className="middle-line" />
          {theme.texts.banner && (
            <span
              dangerouslySetInnerHTML={{
                __html: theme.texts.banner,
              }}
            />
          )}
          <FooterRight>
            <span>Consulte também os</span>
            <p>
              <a
                href={theme.emailParams.serviceTerms}
                target="_blank"
                rel="noreferrer"
              >
                {translator('Termos de Serviço')}
              </a>
              <span>{translator('e')}</span>
              <a
                href={theme.emailParams.serviceTerms}
                target="_blank"
                rel="noreferrer"
              >
                {translator('Políticas de Privacidade')}
              </a>
            </p>
          </FooterRight>
        </Right>
      </Body>
      <Modal
        title={Translator('Seleção de papeis')}
        handleClose={() => makeReduxCloseModal().close({})}
      >
        <ChangeRoles />
      </Modal>
    </Container>
  );
};

export default Login;
