import * as Yup from 'yup';

export enum MessageError {
  REQUIRED_FIELD = 'Campo obrigatório',
  INVALID_EMAIL = 'E-mail inválido',
  INVALID_PHONE = 'Telefone inválido',
}

export const orgSchema = Yup.object().shape({
  name: Yup.string()
    .max(128, 'O nome da org deve conter no máximo 128 caracteres.')
    .required(MessageError.REQUIRED_FIELD),

  shortname: Yup.string()
    .max(32, 'A sigla deve conter no máximo 32 caracteres.')
    .required(MessageError.REQUIRED_FIELD),

  zipcode: Yup.string()
    .min(4, 'CEP inválido, existe(m) digitos faltando')
    .required(MessageError.REQUIRED_FIELD),

  province: Yup.string()
    .min(2, 'O estado deve conter no mínimo 2 caracteres.')
    .required(MessageError.REQUIRED_FIELD),

  city: Yup.string()
    .max(128, 'A cidade deve conter no máximo 128 caracteres.')
    .required(MessageError.REQUIRED_FIELD),

  country: Yup.string()
    .max(3, 'O país deve conter no máximo 3 caracteres.')
    .optional(),

  neighborhood: Yup.string()
    .max(128, 'O bairro deve conter no máximo 128 caracteres.')
    .required(MessageError.REQUIRED_FIELD),

  street: Yup.string()
    .max(256, 'A rua deve conter no máximo 256 caracteres.')
    .required(MessageError.REQUIRED_FIELD),

  number: Yup.string()
    .max(20, 'O número deve conter no máximo 20 caracteres.')
    .required(MessageError.REQUIRED_FIELD),

  complement: Yup.string()
    .max(1024, 'O complemento deve conter no máximo 1024 caracteres.')
    .optional(),

  contactName: Yup.string()
    .max(256, 'O nome do contato deve conter no máximo 256 caracteres.')
    .nullable()
    .optional(),

  contactEmail: Yup.string()
    .max(128, 'O email do contato deve conter no máximo 128 caracteres.')
    .nullable()
    .optional(),

  contactPhone1: Yup.string().nullable().optional(),

  contactPhone2: Yup.string().nullable().optional(),
});
