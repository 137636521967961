import React, { FormEvent, useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Tooltip } from '@mui/material';

import { IconLogoV4H, IconLoginV4H } from 'presentation/base/icons';
import { Button, Input } from 'presentation/components/UI';

import { makeRemoteCreatePasswordRecovery } from 'main/factories/usecases/password/CreatePasswordRecoveryFactory';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { closeModal } from 'utils/closeModal';
import { useHistory } from 'react-router';
import { ThemeContext } from 'App';
import {
  Container,
  Left,
  Right,
  Content,
  Form,
  Title,
  ButtonsContainer,
  ForgotPasswordText,
  ImageContainer,
  VersionContentText,
} from './styles/styledForgotPassword';

const ForgotPassword: React.FC = (): JSX.Element => {
  const [email, setEmail] = useState('');

  const { push } = useHistory();

  const { theme } = useContext(ThemeContext);

  const handleForgot = useCallback(
    // eslint-disable-next-line consistent-return
    (e: FormEvent) => {
      e.preventDefault();

      if (email === '') {
        return toast.error('Preencha todos os campos.');
      }

      makeRemoteCreatePasswordRecovery()
        .create({
          body: {
            email,
          },
        })
        .then(res => {
          toast.success('E-mail de recuperação enviado com sucesso!');
          push('/');
        })
        .catch(err => {
          makeReduxActiveMessage().active({
            active: 'error',
            title: 'Esqueceu a senha?',
            content:
              'Não foi possível solicitar mudança de senha para esse e-mail',
            actionOk: () => closeModal(),
            actionCancel: () => closeModal(),
          });
        });
    },
    [email, push],
  );

  const backToLogin = () => {
    push('/');
  };

  return (
    <Container>
      <Left />
      <Right>
        <Content>
          {theme.images.logo.main && (
            <img src={theme.images.logo.main} alt="Logo" />
          )}
          <Title>Esqueceu sua senha?</Title>
          <Form onSubmit={handleForgot}>
            <Input
              data-testid="input-email"
              label="Email"
              placeholder="Digite seu email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              autoFocus
            />
            <ButtonsContainer>
              <Button
                data-testid="btn-back"
                type="button"
                onClick={backToLogin}
                size="small"
                variant="secundary"
              >
                Cancelar
              </Button>
              <Button data-testid="btn-send" type="submit" size="small">
                Enviar
              </Button>
            </ButtonsContainer>

            <Tooltip
              title="Clique para ver as notas de release"
              placement="top"
              arrow
            >
              <VersionContentText
                href="https://docs.google.com/document/d/1Tp0HF3ESTIYJnkuGTsPuStfQBY33d8gPVPFnY4wAJlA"
                target="_blank"
              >
                v2.46.0
              </VersionContentText>
            </Tooltip>
          </Form>
        </Content>
        <ImageContainer>
          {theme.images.background.main && (
            <img src={theme.images.background.main} alt="Logo" />
          )}
          <Tooltip
            title="Clique para ver as notas de release"
            placement="top"
            arrow
          >
            <ForgotPasswordText
              href="https://docs.google.com/document/d/1Tp0HF3ESTIYJnkuGTsPuStfQBY33d8gPVPFnY4wAJlA"
              target="_blank"
            >
              v2.46.0
            </ForgotPasswordText>
          </Tooltip>
        </ImageContainer>
      </Right>
    </Container>
  );
};

export default ForgotPassword;
