import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import Translator from 'presentation/components/i18n/Translator';
import {
  GetAddressData as RemoteGetAddressData,
  GetCitiesByUf as RemoteGetCitiesByUf,
  GetUfs as RemoteGetUfs,
} from 'domain/usecases/externalServices/remote';
import { makeRemoteGetAddressData } from 'main/factories/usecases/externalServices/GetAddressData';
import { makeRemoteGetCitiesByUf } from 'main/factories/usecases/externalServices/GetCitiesByUf';
import { makeRemoteGetUfs } from 'main/factories/usecases/externalServices/GetUfs';
import { Input, Select } from 'presentation/components/UI';
import { UserByOrg, UserFieldsValidation } from '..';
import { Container, InputFields } from './styles/StyledAddress';

interface ownProps {
  user: UserByOrg;
  onChangeUserInfo: Function;
  editing?: boolean;
}

const Address: React.FC<ownProps> = ({
  user,
  onChangeUserInfo,
  editing = false,
}) => {
  const [ufs, setUfs] = useState<RemoteGetUfs.Model>([]);
  const [cities, setCities] = useState<RemoteGetCitiesByUf.Model>([]);
  const [address, setAddress] = useState(user.address);
  const [country, setCountry] = useState(user.address?.country ?? 'BRA');

  const formattedCountry = country.trim().toUpperCase();
  const [isSelectActiveByCountry, setIsSelectActiveByCountry] = useState(
    formattedCountry === 'BR' || formattedCountry === 'BRA',
  );

  const { setFieldValue, errors, touched, handleBlur } =
    useFormikContext<UserFieldsValidation>();

  useEffect(() => {
    makeRemoteGetUfs()
      .getUfs({})
      .then(res => {
        setUfs(res);
        if (user?.address?.province) {
          onChangeUserInfo({
            ...user,
            address: { ...user.address, province: user?.address?.province },
          });
        }
      })
      .catch(err => {
        console.log('Erro ao buscar UFs: ', err);
      });
  }, []);

  useEffect(() => {
    if (!isSelectActiveByCountry || !user.address || !user?.address?.province)
      return;
    makeRemoteGetCitiesByUf()
      .getCitiesByUf({ uf: user.address.province })
      .then(res => {
        setCities(res);

        if (user?.address?.city)
          onChangeUserInfo((prev: UserByOrg) => {
            return {
              ...prev,
              address: { ...prev.address, city: user?.address?.city },
            };
          });
      })
      .catch(err => {
        console.log('Erro ao buscar cidades: ', err);
      });
  }, [user?.address?.province]);

  const onChangeZipcode = (value: string) => {
    if (value.length >= 9) return;

    onChangeUserInfo({ ...user, address: { ...user.address, zipcode: value } });

    if (!isSelectActiveByCountry) {
      setFieldValue('zipcode', value);
      return;
    }

    if (value.length >= 8 && value !== user?.address?.zipcode) {
      const dataToSend: RemoteGetAddressData.Params = {
        zipcode: value,
      };

      makeRemoteGetAddressData()
        .getAddressData(dataToSend)
        .then(response => {
          const updatedForm = {
            zipcode: value,
            city: response.localidade ?? '',
            neighborhood: response.bairro ?? '',
            street: response.logradouro ?? '',
            province: response.uf ?? '',
          };

          setFieldValue('zipcode', updatedForm.zipcode);
          setFieldValue('city', updatedForm.city);
          setFieldValue('neighborhood', updatedForm.neighborhood);
          setFieldValue('street', updatedForm.street);
          setFieldValue('province', updatedForm.province);
          setFieldValue('number', '');
          setFieldValue('complement', '');

          onChangeUserInfo({
            ...user,
            address: {
              ...user.address,
              ...updatedForm,
              number: '',
              complement: '',
            },
          });
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error('Erro: ', error);
        });
    }
  };

  const onChangeState = (value: string) => {
    onChangeUserInfo((prev: UserByOrg) => {
      return {
        ...prev,
        address: { ...prev.address, province: value },
      };
    });
    setFieldValue('province', value);

    // setSelectedUf(value);
  };

  const onChangeComplement = (value: string) => {
    setFieldValue('complement', value);
    onChangeUserInfo({
      ...user,
      address: { ...user.address, complement: value },
    });
  };

  const onChangeCity = (value: string) => {
    setFieldValue('city', value);
    onChangeUserInfo({ ...user, address: { ...user.address, city: value } });
  };

  const onChangeNeighborhood = (value: string) => {
    setFieldValue('neighborhood', value);
    onChangeUserInfo({
      ...user,
      address: { ...user.address, neighborhood: value },
    });
  };

  const onChangeStreet = (value: string) => {
    setFieldValue('street', value);
    onChangeUserInfo({ ...user, address: { ...user.address, street: value } });
  };

  return (
    <Container>
      <InputFields>
        <Input
          mask=""
          label={`${Translator('CEP')}`}
          name="zipcode"
          onBlur={handleBlur}
          defaultValue={user?.address?.zipcode}
          value={user?.address?.zipcode}
          message={touched.zipcode ? errors.zipcode : ''}
          error={Boolean(touched.zipcode && errors?.zipcode)}
          onChange={e => {
            setFieldValue('zipcode', e.target.value);
            onChangeZipcode(e.target.value.replace(/[^A-Z0-9]/gi, ''));
          }}
          autoFocus
        />

        {isSelectActiveByCountry ? (
          <Select
            label={`${Translator('Estado')}`}
            name="province"
            onBlur={handleBlur}
            defaultValue={user?.address?.province}
            message={touched.province ? errors.province : ''}
            error={Boolean(touched.province && errors?.province)}
            onChange={e => {
              setFieldValue('province', e.target.value);
              onChangeState(e.target.value.trim());
            }}
            value={user?.address?.province}
          >
            <option key={-1}>Selecione o Estado</option>
            {ufs &&
              ufs.length > 0 &&
              ufs.map(uf => (
                <option key={uf.id} value={uf.sigla}>
                  {uf.sigla}
                </option>
              ))}
          </Select>
        ) : (
          <Input
            label={`${Translator('Estado')}`}
            name="province"
            onBlur={handleBlur}
            defaultValue={user?.address?.province}
            message={touched.province ? errors.province : ''}
            error={Boolean(touched.province && errors?.province)}
            onChange={e => {
              setFieldValue('province', e.target.value);
              onChangeState(e.target.value);
            }}
            value={user?.address?.province}
          />
        )}
        {isSelectActiveByCountry ? (
          <Select
            label={`${Translator('Cidade')}`}
            name="city"
            onBlur={handleBlur}
            defaultValue={user?.address?.city}
            message={touched.city ? errors.city : ''}
            error={Boolean(touched.city && errors?.city)}
            onChange={e => {
              setFieldValue('city', e.target.value);
              onChangeCity(e.target.value.trim());
            }}
            value={user?.address?.city}
          >
            <option key={-1} value="">
              Selecione a cidade
            </option>
            {cities &&
              cities.length > 0 &&
              cities.map(city => (
                <option key={city.id} value={city.nome}>
                  {city.nome}
                </option>
              ))}
          </Select>
        ) : (
          <Input
            label={`${Translator('Cidade')}`}
            name="city"
            onBlur={handleBlur}
            defaultValue={user?.address?.city}
            message={touched.city ? errors.city : ''}
            error={Boolean(touched.city && errors?.city)}
            onChange={e => {
              setFieldValue('city', e.target.value);
              onChangeCity(e.target.value);
            }}
            value={user?.address?.city}
          />
        )}
      </InputFields>
      <InputFields>
        <Input
          label={`${Translator('Bairro')}`}
          name="neighborhood"
          onBlur={handleBlur}
          message={touched.neighborhood ? errors.neighborhood : ''}
          error={Boolean(touched.neighborhood && errors?.neighborhood)}
          defaultValue={user?.address?.neighborhood}
          onChange={e => {
            setFieldValue('neighborhood', e.target.value);
            onChangeNeighborhood(e.target.value);
          }}
          value={user?.address?.neighborhood}
        />
        <Input
          label={`${Translator('Logradouro')}`}
          name="street"
          onBlur={handleBlur}
          message={touched.street ? errors.street : ''}
          error={Boolean(touched.street && errors?.street)}
          defaultValue={user?.address?.street}
          onChange={e => {
            setFieldValue('street', e.target.value);
            onChangeStreet(e.target.value);
          }}
          value={user?.address?.street}
        />
        <Input
          label={`${Translator('Número')}`}
          name="number"
          onBlur={handleBlur}
          message={touched.number ? errors.number : ''}
          error={Boolean(touched.number && errors?.number)}
          defaultValue={user?.address?.number}
          onChange={e => {
            setFieldValue('number', e.target.value);
            onChangeUserInfo({
              ...user,
              address: { ...user.address, number: e.target.value },
            });
          }}
          value={user?.address?.number}
        />
      </InputFields>
      <InputFields>
        <Input
          label={`${Translator('Complemento')}`}
          name="complement"
          onBlur={handleBlur}
          message={touched.complement ? errors.complement : ''}
          error={Boolean(touched.complement && errors?.complement)}
          onChange={e => {
            setFieldValue('complement', e.target.value);
            onChangeComplement(e.target.value);
          }}
          defaultValue={user?.address?.complement}
          value={user?.address?.complement}
        />
      </InputFields>
    </Container>
  );
};

export default Address;
